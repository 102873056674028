import * as React from 'react';
import 'pickr-widget/dist/pickr.min.css';
import '../../../styles/app.css';
import {
  Consumer as ThemeConsumer,
  Provider as ThemeProvider
} from '../../shared/context/themeContext';
import Dashboard from '../../dashboard/containers/Dashboard';
import history from '../history';
import Notifications from '../../shared/notifications/components/Notifications';
import { config } from '../config';
import { LogLevel } from '../../shared/logger/models/logger';

interface Props {
  isAuthorized: boolean;
  isConnected: boolean;

  sendLogToServer: (log: { message: string, level: LogLevel }) => void;
}

interface State {
  themeContextValue: {
    setTheme: any;
    selectedTheme: string;
  };
}

export default class MainContainer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      themeContextValue: {
        setTheme: this.setTheme,
        selectedTheme: config.theme
      }
    };
  }

  componentDidMount() {
    this._checkAuthorization();
    this.state.themeContextValue.setTheme(config.theme);
  }

  componentDidUpdate() {
    this._checkAuthorization();
  }

  componentDidCatch(error: Error) {
    this.props.sendLogToServer({
      message: '' + error.stack,
      level: LogLevel.FATAL
    });
  }

  _checkAuthorization() {
    const notConnected = !this.props.isAuthorized;
    if (notConnected) {
      history.replace('/login');
    }
  }

  _checkConnectionAndAuthorization() {
    const notConnected = !this.props.isAuthorized || !this.props.isConnected;
    if (notConnected) {
      history.replace('/login');
    }
  }

  setTheme = (theme: string) => {
    this.setState({
      themeContextValue: {
        ...this.state.themeContextValue,
        selectedTheme: theme
      }
    });
  }

  render() {
    const bannerName = config.branding.banner;
    const banner = `${config.subfolder}/logo/${bannerName}`;
    const bannerBackground = config.branding.bannerBackground;
    const bannerBackgroundString = bannerBackground ? bannerBackground : 'transparent'
    return (
      <ThemeProvider value={this.state.themeContextValue}>
        <ThemeConsumer>
          {({ selectedTheme }) => (
              <div className="h-100">
                {
                    bannerName && (
                        <div className="banner" style={{background: bannerBackgroundString}}>
                          <img src={banner} className="bannerImage" />
                        </div>
                    )
                }
                <div
                    className={
                      (selectedTheme ? selectedTheme + '' : '') + (bannerName ? ' banner-reduced' : ' h-100')
                    }
                >
                  <div className="container-fluid h-100">
                    <Dashboard />
                    <Notifications />
                  </div>
                </div>
              </div>
          )
          }
        </ThemeConsumer>
      </ThemeProvider>
    );
  }
}
