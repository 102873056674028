import { ITableSort, ITableColumn } from '../shared/ui/models/table';

declare var staticConfig: any; // connection configuration
declare var branding: any; // variable holds map with branding colors
declare var firebaseParams: any; 
declare var brandingTitle: any;
declare var company: any;
declare var ssoEnabled: any;
declare var redirectUrl: any;
declare var customCss: any;
declare var readonlyMarketsheet: any;
declare var useDB: any;
declare var sanityChecksEnabled: any;
declare var priceAlarmsEnabled: any;
declare var gridLayoutEnabled: any;
declare var orderRestrictions: any;
declare var tradeLimitMax: any;
declare var ssoTokenCountdown: any;
declare var counterparties: any;
declare var theme: any;
declare var showUnitCurrency: any;

const isBranding = typeof branding !== 'undefined';
const brandingExists = (key: string) => isBranding && key in branding && !!branding[key];
const isFirebase = typeof firebaseParams !== 'undefined';
const firebaseExists = (key: string) => isFirebase && key in firebaseParams && !!firebaseParams[key];
const variableCheck = (variable: any, staticConfigKey: string, envVar: any, defaultValue: any) => { 
  if (variable !== undefined) {
    return variable;
  } else if (typeof staticConfig !== 'undefined') {
    return staticConfig[staticConfigKey];
  } else if (envVar !== undefined) {
    try {
      return JSON.parse(envVar!);
    } catch (e) {
      return envVar;
    }
  } else {
    return defaultValue;
  }
}

let counterpartiesVal = typeof counterparties !== 'undefined' ? counterparties : undefined;
if (!counterpartiesVal) {
  counterpartiesVal = process.env.REACT_APP_COUNTERPARTIES ? process.env.REACT_APP_COUNTERPARTIES : '';
}
const counterpartiesMap = counterpartiesVal.split(',').map(cp => cp.toLowerCase()).reduce(
  (acc: any, cp: string) => {
    acc['counterparty-'+cp] = {
      background: (brandingExists('counterparty-' + cp + 'Background')) ? branding['counterparty-' + cp + 'Background'] : 'rgba(255, 255, 255, 0)',
      font: (brandingExists('counterparty-' + cp + 'Font')) ? branding['counterparty-' + cp + 'Font'] : 'rgb(255, 255, 255, 1.0)'
    };
    return acc;
  }, {}
);

export const config = {
  serverURL:
    typeof staticConfig !== 'undefined'
      ? staticConfig.serverProtocol +
      '://' +
      staticConfig.serverHost +
      ':' +
      staticConfig.serverPort +
      staticConfig.serverSubfolder
      : process.env.REACT_APP_API_PROTOCOL +
      '://' +
      process.env.REACT_APP_API_URL +
      ':' +
      process.env.REACT_APP_API_PORT +
      process.env.REACT_APP_SUBFOLDER,
  websocketsURL:
    typeof staticConfig !== 'undefined'
      ? staticConfig.serverWsProtocol +
      '://' +
      staticConfig.serverHost +
      ':' +
      staticConfig.serverPort +
      staticConfig.serverSubfolder
      : process.env.REACT_APP_WS_PROTOCOL +
      '://' +
      process.env.REACT_APP_API_URL +
      ':' +
      process.env.REACT_APP_API_PORT +
      process.env.REACT_APP_SUBFOLDER,
  subfolder:
    typeof staticConfig !== 'undefined'
      ? staticConfig.serverSubfolder
      : process.env.REACT_APP_SUBFOLDER,
  notifications: {
    removeTimer: 5000 // ms
  },
  ssoEnabled: variableCheck(typeof ssoEnabled !== 'undefined' ? ssoEnabled : undefined, 'ssoEnabled', process.env.REACT_APP_SSO_ENABLED, false),
  ssoTokenCountdown: variableCheck(typeof ssoTokenCountdown !== 'undefined' ? ssoTokenCountdown : undefined, 'ssoTokenCountdown', process.env.REACT_APP_SSO_TOKEN_COUNTDOWN, false),
  redirectUrl: typeof redirectUrl !== 'undefined' ? redirectUrl : (process.env.REACT_APP_SSO_REDIRECT),
  customCss: typeof customCss !== 'undefined' && !!customCss,
  maxConnectedMarkets: 8,
  sidebarDashboardsListLimit: 5,
  sidebarFavoritesListLimit: 3,
  reconnectionAttempts: 2,
  orderbookDepthSteps: [1, 2, 4, 8, Infinity],
  tablePageSize: 60,
  readonlyMarketsheet: variableCheck(typeof readonlyMarketsheet !== 'undefined' ? readonlyMarketsheet : undefined, 'readonlyMarketsheet', process.env.REACT_APP_READONLY_MARKETSHEET, false),   
  tradeLimit: 1000,
  tradeLimitMax: variableCheck(typeof tradeLimitMax !== 'undefined' ? tradeLimitMax : undefined, 'tradeLimitMax', process.env.REACT_APP_TRADE_LIMIT_MAX, 0),   
  orderRestrictions:  variableCheck(typeof orderRestrictions !== 'undefined' ? orderRestrictions : undefined, 'orderRestrictions', process.env.REACT_APP_ORDER_RESTRICTIONS, 'NONE'),   
  useDB: variableCheck(typeof useDB !== 'undefined' ? useDB : undefined, 'useDB', process.env.REACT_APP_USE_DB, false),   
  priceAlarmsEnabled: variableCheck(typeof priceAlarmsEnabled !== 'undefined' ? priceAlarmsEnabled : undefined, 'priceAlarmsEnabled', process.env.REACT_APP_PRICE_ALARMS_ENABLED, false), 
  gridLayoutEnabled: variableCheck(typeof gridLayoutEnabled !== 'undefined' ? gridLayoutEnabled : undefined, 'gridLayoutEnabled', process.env.REACT_APP_GRID_LAYOUT_ENABLED, true),
  sanityChecksEnabled: variableCheck(typeof sanityChecksEnabled !== 'undefined' ? sanityChecksEnabled : undefined, 'sanityChecksEnabled', process.env.REACT_APP_SANITY_CHECKS, true),
  theme: variableCheck(typeof theme !== 'undefined' ? theme : undefined, 'theme', process.env.REACT_APP_THEME, 'theme-dark'),
  showUnitCurrency: variableCheck(typeof showUnitCurrency !== 'undefined' ? showUnitCurrency : undefined, 'showUnitCurrency', process.env.REACT_APP_SHOW_UNIT_CURRENCY, true),
  branding: {
    colors: {
      order: {
        background: (brandingExists('orderBackground')) ? branding.orderBackground : 'rgba(255, 255, 255, 0)',
        font: (brandingExists('orderFont')) ? branding.orderFont : 'rgb(255, 255, 255, 1.0)'
      },
      orderOwn: {
        background: (brandingExists('orderOwnBackground')) ? branding.orderOwnBackground : 'rgba(255, 200, 0, 0)',
        font: (brandingExists('orderOwnFont')) ? branding.orderOwnFont : 'rgba(242, 171, 60, 1.0)'
      },
      orderReadonly: {
        background: (brandingExists('orderReadonlyBackground')) ? branding.orderReadonlyBackground : 'rgba(255, 255, 255, 0)',
        font: (brandingExists('orderReadonlyFont')) ? branding.orderReadonlyFont : 'rgba(255, 16, 0, 1.0)'
      },
      orderSuspended: {
        background: (brandingExists('orderSuspendedBackground')) ? branding.orderSuspendedBackground : 'rgba(97, 98, 101, 0)',
        font: (brandingExists('orderSuspendedFont')) ? branding.orderSuspendedFont : 'rgba(97,98,101, 1)'
      },
      quoteRequest: {
        background: (brandingExists('quoteRequestBackground')) ? branding.quoteRequestBackground : 'rgba(19, 153, 244, 0)',
        font: (brandingExists('quoteRequestFont')) ? branding.quoteRequestFont : 'rgba(19,153,244, 1)'
      },
      orderHighlight: {
        background: (brandingExists('orderHighlightBackground')) ? branding.orderHighlightBackground : 'rgba(255, 255, 0, 1.0)',
        font: (brandingExists('orderHighlightFont')) ? branding.orderHighlightFont :  'none'
      },
      orderPreview: {
        background: (brandingExists('orderPreviewBackground')) ? branding.orderPreviewBackground : 'rgba(255, 0, 0, 0.1)',
        font: (brandingExists('orderPreviewFont')) ? branding.orderPreviewFont :  'none'
      },
      bidOverlay: {
        background: (brandingExists('bidOverlayBackground')) ? branding.bidOverlayBackground : 'rgba(2, 113, 0, 0.18)',
        font: (brandingExists('bidOverlayFont')) ? branding.bidOverlayFont :  'none'
      },
      askOverlay: {
        background: (brandingExists('askOverlayBackground')) ? branding.askOverlayBackground : 'rgba(123, 0, 0, 0.18)',
        font: (brandingExists('askOverlayFont')) ? branding.askOverlayFont :  'none'
      },
      notInteractive: {
        background: (brandingExists('notInteractiveBackground')) ? branding.notInteractiveBackground : 'rgba(210, 210, 210, 0.2)',
        font: (brandingExists('notInteractiveFont')) ? branding.notInteractiveFont :  'none'
      },
      insideInformations: {
        background: (brandingExists('insideInformationsBackground')) ? branding.insideInformationsBackground : 'rgba(128, 128, 129, 0.1)',
        font: (brandingExists('insideInformationsFont')) ? branding.insideInformationsFont :  'none'
      },
      borderColor: {
        background: (brandingExists('borderColor')) ? branding.borderColor : 'rgba(0, 0, 0, 0)',
        font: 'none',
        active: (brandingExists('borderActive')) ? (branding.borderActive === 'true' ? 'active' : '') : 'active'
      },
      implied: {
        background: (brandingExists('impliedBackground')) ? branding.orderOwnBackground : 'rgba(40, 200, 255, 0)',
        font: (brandingExists('impliedFont')) ? branding.impliendFont : 'rgba(40, 200, 255, 1.0)'
      },
      routed: {
        background: (brandingExists('routedBackground')) ? branding.routedBackground : 'rgba(69, 191, 163, 0.3)',
        font: (brandingExists('routedFont')) ? branding.routedFont : 'rgba(0, 0, 0, 1.0)'
      },
      quarter_hour: {
        background: (brandingExists('periodTypeQuarterHourBackground')) ? branding.periodTypeQuarterHourBackground : 'rgba(255, 255, 255, 0)',
        font: 'none'
      },
      half_hour: {
        background: (brandingExists('periodTypeHalfHourBackground')) ? branding.periodTypeHalfHourBackground : 'rgba(255, 255, 255, 0)',
        font: 'none'
      },
      hour: {
        background: (brandingExists('periodTypeHourBackground')) ? branding.periodTypeHourBackground : 'rgba(255, 255, 255, 0)',
        font: 'none'
      },
      block_2_h: {
        background: (brandingExists('periodTypeBlock2HBackground')) ? branding.periodTypeBlock2HBackground : 'rgba(255, 255, 255, 0)',
        font: 'none'
      },
      block_4_h: {
        background: (brandingExists('periodTypeBlock4HBackground')) ? branding.periodTypeBlock4HBackground : 'rgba(255, 255, 255, 0)',
        font: 'none'
      },
      ...counterpartiesMap
    },
    clientColors: {
      primary:
        brandingExists('primaryColor')
          ? branding.primaryColor
          : 'rgba(242, 171, 60, 1)', // 'rgba(179, 154, 104, 1)',
      secondary:
        brandingExists('secondaryColor')
          ? branding.secondaryColor
          : 'rgba(0, 0, 0, 1)', // 'rgba(53, 60, 73, 1)',
      tableOdd: brandingExists('tableOddColor')
          ? branding.tableOddColor
          : 'rgba(232, 233, 237, 1)',  
      tableEven:
        brandingExists('tableEvenColor')
          ? branding.tableEvenColor
          : 'rgba(0, 0, 0, 0)'  ,
      approval:
        brandingExists('approvalButtonColor')
          ? branding.approvalButtonColor
          : brandingExists('primaryColor')
          ? branding.primaryColor
          : 'rgba(0, 173, 151, 1)',
      cancellation:
        brandingExists('cancellationButtonColor')
          ? branding.cancellationButtonColor
          : brandingExists('secondaryColor')
          ? branding.secondaryColor
          : 'rgb(241, 95, 95, 1)',
      cancellationFont:
        brandingExists('cancellationButtonColor')
          ? 'rgba(0, 0, 0, 1)'
          : brandingExists('primaryColor')
            ? branding.primaryColor
            : 'rgba(0, 0, 0, 1)',
      approvalFont:
        brandingExists('approvalButtonColor')
          ? 'rgba(0, 0, 0, 1)'
          : brandingExists('secondaryColor')
            ? branding.secondaryColor
            : 'rgba(0, 0, 0, 1)',
      secondaryFont:
        brandingExists('secondaryFontColor')
          ? branding.secondaryFontColor
          : 'rgba(255, 255, 255, 1)'
      
    },
    logo: brandingExists('logo') ? branding.logo : 'estar_logo.svg',
    logoSidebar: brandingExists('logoSidebar') ? branding.logoSidebar : 'estar_logo_white.svg',
    watermark: brandingExists('watermark') ? branding.watermark : '',
    banner: brandingExists('banner') ? branding.banner : '',
    bannerBackground: brandingExists('bannerBackground') ? branding.bannerBackground : '',
    title: typeof brandingTitle !== 'undefined' ? brandingTitle : 'E-Star WebTrader',
    company: typeof company !== 'undefined' ? {name: company, link: null} : {name: 'E-Star Trading GmbH', link: 'https://www.e-star.com'},
    fonts: {
      clientFont: {
        name: brandingExists('font') && branding.font.name
          ? branding.font.name
          : 'Roboto',
      
        directory: brandingExists('font') && branding.font.directory
          ? branding.font.directory
          : 'static/media'
      }
    }
  },
  firebase: {
    apiKey: firebaseExists('apiKey') ? firebaseParams.apiKey : 'AIzaSyBVDDWqPTTI0lDl9_F8zciqmyh_k8VauzQ',
    authDomain: firebaseExists('authDomain') ? firebaseParams.authDomain : 'meet-78434.firebaseapp.com',
    databaseURL: firebaseExists('databaseURL') ? firebaseParams.databaseURL : 'https://meet-78434.firebaseio.com',
    projectId: firebaseExists('projectId') ? firebaseParams.projectId : 'meet-78434',
    storageBucket: firebaseExists('storageBucket') ? firebaseParams.storageBucket : 'meet-78434.appspot.com',
    messagingSenderId: firebaseExists('messagingSenderId') ? firebaseParams.messagingSenderId : '393512531408',
    appId: firebaseExists('appId') ? firebaseParams.appId : '1:393512531408:web:60abb670a3259265b535d5'
  },
  settings: {
    general: {
      savingInterval: {
        value: '30000',
        type: 'number'
      },
      compactColumns: {
        type: 'checkbox',
        value: false
      }
    }
  },
  ui: {
    market: {
      columns: <ITableColumn[]> [
        {
          group: 'bidPrices',
          name: 'counterparty',
          title: 'columns.market.bc',
          originalName: 'bc',
          rowVisible: true,
          formatters: ['counterparty'],
          combine: true
        },
        {
          group: 'bidPrices',
          name: 'quantity',
          title: 'columns.market.bq',
          originalName: 'bq',
          rowVisible: true,
          formatters: ['quantity'],
          combine: true
        },
        {
          group: 'bidPrices',
          name: 'price',
          title: 'columns.market.bp',
          originalName: 'bp',
          rowVisible: true,
          formatters: ['badge'],
          combine: true
        },
        {
          group: 'askPrices',
          name: 'price',
          title: 'columns.market.ap',
          originalName: 'ap',
          rowVisible: true,
          formatters: ['badge'],
          combine: true
        },
        {
          group: 'askPrices',
          name: 'quantity',
          title: 'columns.market.aq',
          originalName: 'aq',
          rowVisible: true,
          formatters: ['quantity'],
          combine: true
        },
        {
          group: 'askPrices',
          name: 'counterparty',
          title: 'columns.market.ac',
          originalName: 'ac',
          rowVisible: true,
          formatters: ['counterparty'],
          combine: true
        },
        {
          group: 'trades',
          name: 'execPrice',
          title: 'columns.market.lp',
          originalName: 'lp',
          rowVisible: true,
          insideInfo: true,
          formatters: ['badge', 'execPrice']
        }
      ]
    },
    orderTable: {
      columns: <ITableColumn[]> [
        {
          name: '$index',
          title: '',
          originalName: '$index',
          rowVisible: false
        },
        {
          name: 'id',
          title: 'columns.orderTable.orderId',
          originalName: 'orderId',
          rowVisible: true
        },
        {
          name: 'contract',
          title: 'columns.orderTable.contractId',
          originalName: 'contractId',
          rowVisible: true,
          searchable: true
        },
        {
          name: 'contractTitle',
          title: 'columns.orderTable.contractTitle',
          originalName: 'contractTitle',
          rowVisible: false,
          searchable: true
        },
        {
          name: 'buySell',
          title: 'columns.orderTable.buy',
          originalName: 'buy',
          rowVisible: true
        },
        {
          name: 'limit',
          title: 'columns.orderTable.limit',
          originalName: 'limit',
          rowVisible: true
        },
        {
          name: 'remainingQuantity',
          title: 'columns.orderTable.remainingQuantity',
          originalName: 'remainingQuantity',
          rowVisible: true
        },
        {
          name: 'orderedQuantity',
          title: 'columns.orderTable.orderedQuantity',
          originalName: 'orderedQuantity',
          rowVisible: true
        },
        {
          name: 'qtyUnit',
          title: 'columns.orderTable.qtyUnit',
          originalName: 'qtyUnit',
          rowVisible: true
        },
        {
          name: 'userId',
          title: 'columns.orderTable.userId',
          originalName: 'userId',
          rowVisible: true
        },
        {
          name: 'date',
          title: 'columns.orderTable.date',
          originalName: 'timestamp',
          rowVisible: true,
          sortWith: 'timestamp'
        },
        {
          name: 'timestamp',
          title: 'columns.orderTable.time',
          originalName: 'timestamp',
          rowVisible: true,
          sortWith: 'date'
        },
        {
          name: 'restriction',
          title: 'columns.orderTable.restriction',
          originalName: 'restriction',
          rowVisible: true
        }
      ],
      tableColumns: ['$index', 'contract', 'buySell', 'limit', 'remainingQuantity', 'orderedQuantity', 'userId', 'date', 'timestamp'],
      recentTableColumns: ['$index', 'contract', 'buySell', 'limit', 'remainingQuantity'],
      defaultSorting: <ITableSort[]> [
        {
          columnName: 'timestamp',
          direction: -1
        }],
      recentDefaultSorting: <ITableSort[]> [
        {
          columnName: 'timestamp',
          direction: -1
        }
      ],
      cellActions: {
        '*': 'showPopover'
      }
    },
    tradeTable: {
      columns: <ITableColumn[]> [
        {
          name: '$index',
          title: '',
          originalName: '$index',
          rowVisible: false
        },
        {
          name: 'contract',
          title: 'columns.tradeTable.contractTitle',
          originalName: 'contractTitle',
          rowVisible: true,
          searchable: true
        },
        {
          name: 'paidGiven',
          title: 'columns.tradeTable.paidGiven',
          originalName: 'paid',
          rowVisible: true,
          searchable: true
        },
        {
          name: 'price',
          title: 'columns.tradeTable.execPrice',
          originalName: 'execPrice',
          rowVisible: true
        },
        {
          name: 'quantity',
          title: 'columns.tradeTable.execQty',
          originalName: 'execQty',
          rowVisible: true
        },
        {
          name: 'qtyUnit',
          title: 'columns.tradeTable.qtyUnit',
          originalName: 'qtyUnit',
          rowVisible: true
        },
        {
          name: 'aggressor',
          title: 'columns.tradeTable.aggressor',
          originalName: 'aggressor',
          rowVisible: true
        },
        {
          name: 'initiator',
          title: 'columns.tradeTable.initiator',
          originalName: 'initiator',
          rowVisible: true
        },
        {
          name: 'date',
          title: 'columns.tradeTable.date',
          originalName: 'timestamp',
          rowVisible: true,
          sortWith: 'timestamp'
        },
        {
          name: 'timestamp',
          title: 'columns.tradeTable.time',
          originalName: 'timestamp',
          rowVisible: true,
          sortWith: 'date'
        }
      ],
      tableColumns: ['$index', 'contract', 'paidGiven', 'price', 'quantity', 'date', 'timestamp'],
      recentTableColumns: ['$index', 'contract', 'price', 'quantity'],
      defaultSorting: <ITableSort[]> [
        {
          columnName: 'timestamp',
          direction: -1
        }],
      recentDefaultSorting: <ITableSort[]> [
        {
          columnName: 'timestamp',
          direction: -1
        }
      ]
    },
    ownTradeTable: {
      columns: <ITableColumn[]> [
        {
          name: '$index',
          title: '',
          originalName: '$index',
          rowVisible: false
        },
        {
          name: 'id',
          title: 'columns.tradeTable.tradeId',
          originalName: 'tradeId',
          rowVisible: true
        },
        {
          name: 'contract',
          title: 'columns.tradeTable.contractTitle',
          originalName: 'contractTitle',
          rowVisible: true,
          searchable: true
        },
        {
          name: 'buy',
          title: 'columns.tradeTable.buySell',
          originalName: 'buy',
          rowVisible: true
        },
        {
          name: 'price',
          title: 'columns.tradeTable.execPrice',
          originalName: 'execPrice',
          rowVisible: true
        },
        {
          name: 'quantity',
          title: 'columns.tradeTable.execQty',
          originalName: 'execQty',
          rowVisible: true
        },
        {
          name: 'qtyUnit',
          title: 'columns.tradeTable.qtyUnit',
          originalName: 'qtyUnit',
          rowVisible: true
        },
        {
          name: 'aggressor',
          title: 'columns.tradeTable.aggressor',
          originalName: 'aggressor',
          rowVisible: true
        },
        {
          name: 'initiator',
          title: 'columns.tradeTable.initiator',
          originalName: 'initiator',
          rowVisible: true
        },
        {
          name: 'date',
          title: 'columns.tradeTable.date',
          originalName: 'timestamp',
          rowVisible: true,
          sortWith: 'timestamp'
        },
        {
          name: 'timestamp',
          title: 'columns.tradeTable.time',
          originalName: 'timestamp',
          rowVisible: true,
          sortWith: 'date'
        }
      ],
      tableColumns: ['$index', 'contract', 'buy', 'price', 'quantity', 'aggressor', 'initiator', 'date', 'timestamp'],
      recentTableColumns: ['$index', 'contract', 'buy', 'price', 'quantity'],
      defaultSorting: <ITableSort[]> [
        {
          columnName: 'timestamp',
          direction: -1
        }],
      recentDefaultSorting: <ITableSort[]> [
        {
          columnName: 'timestamp',
          direction: -1
        }
      ]
    },
    tradeReportingTable: {
      columns: <ITableColumn[]> [
        {
          name: '$index',
          title: '',
          originalName: '$index',
          rowVisible: false
        },
        {
          name: 'id',
          title: 'columns.tradeTable.tradeId',
          originalName: 'tradeId',
          rowVisible: true
        },
        {
          name: 'contract',
          title: 'columns.tradeTable.contractTitle',
          originalName: 'contractTitle',
          rowVisible: true,
          searchable: true
        },
        {
          name: 'buy',
          title: 'columns.tradeTable.buySell',
          originalName: 'buy',
          rowVisible: true
        },
        {
          name: 'price',
          title: 'columns.tradeTable.execPrice',
          originalName: 'execPrice',
          rowVisible: true
        },
        {
          name: 'quantity',
          title: 'columns.tradeTable.execQty',
          originalName: 'execQty',
          rowVisible: true
        },
        {
          name: 'qtyUnit',
          title: 'columns.tradeTable.qtyUnit',
          originalName: 'qtyUnit',
          rowVisible: true
        },
        {
          name: 'aggressor',
          title: 'columns.tradeTable.aggressor',
          originalName: 'aggressor',
          rowVisible: true,
          searchable: true
        },
        {
          name: 'initiator',
          title: 'columns.tradeTable.initiator',
          originalName: 'initiator',
          rowVisible: true,
          searchable: true
        },
        {
          name: 'buyBusinessUnitId',
          title: 'columns.tradeTable.buyBusinessUnitId',
          originalName: 'buyBusinessUnitIdExec',
          rowVisible: true
        },
        {
          name: 'buyPortfolioId',
          title: 'columns.tradeTable.buyPortfolioId',
          originalName: 'buyPortfolioIdExec',
          rowVisible: true
        },
        {
          name: 'sellBusinessUnitId',
          title: 'columns.tradeTable.sellBusinessUnitId',
          originalName: 'sellBusinessUnitIdExec',
          rowVisible: true
        },
        {
          name: 'sellPortfolioId',
          title: 'columns.tradeTable.sellPortfolioId',
          originalName: 'sellPortfolioIdExec',
          rowVisible: true
        },
        {
          name: 'date',
          title: 'columns.tradeTable.date',
          originalName: 'timestamp',
          rowVisible: true,
          sortWith: 'timestamp'
        },
        {
          name: 'timestamp',
          title: 'columns.tradeTable.time',
          originalName: 'timestamp',
          rowVisible: true,
          sortWith: 'date'
        }
      ],
      tableColumns: ['$index', 'contract', 'buy', 'price', 'quantity', 'aggressor', 'initiator', 'date', 'timestamp'],
      recentTableColumns: ['$index', 'contract', 'buy', 'price', 'quantity'],
      defaultSorting: <ITableSort[]> [
        {
          columnName: 'timestamp',
          direction: -1
        }],
      recentDefaultSorting: <ITableSort[]> [
        {
          columnName: 'timestamp',
          direction: -1
        }
      ]
    },
    requestTable: {
      columns: <ITableColumn[]> [
        {
          name: '$index',
          title: '',
          originalName: '$index',
          rowVisible: false
        },
        {
          name: 'id',
          title: 'columns.requestTable.id',
          originalName: 'id',
          rowVisible: true
        },
        {
          name: 'contract',
          title: 'columns.requestTable.contractTitle',
          originalName: 'contractTitle',
          rowVisible: true,
          searchable: true
        },
        {
          name: 'requester',
          title: 'columns.requestTable.requesterName',
          originalName: 'requesterName',
          rowVisible: true
        },
        {
          name: 'answerer',
          title: 'columns.requestTable.answererName',
          originalName: 'traderName',
          rowVisible: true
        },
        {
          name: 'buy',
          title: 'columns.requestTable.buy',
          originalName: 'buy',
          rowVisible: true
        },
        {
          name: 'quantity',
          title: 'columns.requestTable.quantity',
          originalName: 'quantity',
          rowVisible: true
        },
        {
          name: 'price',
          title: 'columns.requestTable.price',
          originalName: 'quote',
          rowVisible: true
        },
        {
          name: 'validFrom',
          title: 'columns.requestTable.validFrom',
          originalName: 'validFrom',
          rowVisible: true
        },
        {
          name: 'validTo',
          title: 'columns.requestTable.validTo',
          originalName: 'validTo',
          rowVisible: true
        },
        {
          name: 'state',
          title: 'columns.requestTable.state',
          originalName: 'state',
          rowVisible: false
        },
        {
          name: 'comment',
          title: 'columns.requestTable.comment',
          originalName: 'comment',
          rowVisible: true
        },
        {
          name: 'reference',
          title: 'columns.requestTable.reference',
          originalName: 'reference',
          rowVisible: true
        },
        {
          name: 'requesterBusinessUnitId',
          title: 'columns.requestTable.requesterBusinessUnit',
          originalName: 'requesterBusinessUnitId',
          rowVisible: true
        },
        {
          name: 'requesterPortfolioId',
          title: 'columns.requestTable.requesterPortfolio',
          originalName: 'requesterPortfolioId',
          rowVisible: true
        },
        {
          name: 'traderBusinessUnitId',
          title: 'columns.requestTable.traderBusinessUnit',
          originalName: 'traderBusinessUnitId',
          rowVisible: true
        },
        {
          name: 'traderPortfolioId',
          title: 'columns.requestTable.traderPortfolio',
          originalName: 'traderPortfolioId',
          rowVisible: true
        },
        {
          name: 'meetState',
          title: 'columns.requestTable.state',
          originalName: 'meetState',
          rowVisible: true,
          searchable: true
        }
      ],
      defaultSorting: <ITableSort[]> [
        {
          columnName: 'validTo',
          direction: -1
        }
      ],
      cellActions: {
        '*': 'showPopover'
      },
      cellActionClick: 'right',
      recentTableColumns: ['$index', 'contract', 'buy', 'quantity', 'meetState'],
      tableColumns: ['$index', 'contract', 'requester', 'answerer', 'buy', 'quantity', 'price', 'validTo']
    },
    basketOrderTable: {
      columns: <ITableColumn[]> [
        {
          name: '$index',
          title: '',
          originalName: '$index',
          rowVisible: false
        },
        {
          name: 'date',
          title: 'columns.basket.date',
          originalName: 'date',
          rowVisible: true
        },
        {
          name: 'timeShort',
          title: 'columns.basket.time',
          originalName: 'timeShort',
          rowVisible: true
        },
        {
          name: 'periodShort',
          title: 'columns.basket.periodShort',
          originalName: 'periodShort',
          rowVisible: true,
        },
        {
          name: 'grid',
          title: 'columns.basket.grid',
          originalName: 'grid',
          rowVisible: true,
          searchable: true
        },
        {
          name: 'buySell',
          title: 'columns.basket.buySell',
          originalName: 'buy',
          rowVisible: true
        },
        {
          name: 'price',
          title: 'columns.basket.limit',
          originalName: 'limit',
          rowVisible: true,
          formatters: ['badge']
        },
        {
          name: 'quantity',
          title: 'columns.basket.quantity',
          originalName: 'quantity',
          rowVisible: true,
          formatters: ['quantity']
        },
        {
          name: 'qtyUnit',
          title: 'columns.basket.qtyUnit',
          originalName: 'qtyUnit',
          rowVisible: true
        },
        {
          name: 'delete',
          title: 'columns.basket.delete',
          originalName: 'delete',
          rowVisible: true,
          formatters: ['delete'],
          hiddenInTooltip: true
        },
        {
          name: 'status',
          title: 'columns.basket.status',
          originalName: 'status',
          rowVisible: true,
          formatters: ['status']
        },
        {
          name: 'statusMessage',
          title: 'columns.basket.statusMessage',
          originalName: 'statusMessage',
          rowVisible: true,
          formatters: ['translate']
        }
      ],
      hiddenColumnNames: ['statusMessage'],
      cellActions: {
        status: 'showPopover',
        delete: 'deleteOrder'
      },
      cellActionClick: 'left',
      defaultSorting: <ITableSort[]> [
        {
          columnName: 'validTo',
          direction: -1
        }
      ]
    },
    priceAlarmTable: {
      columns: <ITableColumn[]> [
        {
          name: '$index',
          title: '',
          originalName: '$index',
          rowVisible: false
        },
        {
          name: 'created',
          title: '',
          originalName: 'created',
          rowVisible: false
        },
        {
          name: 'contract',
          title: 'columns.priceAlarmTable.contract',
          originalName: 'contract',
          rowVisible: true,
          searchable: true
        },
        {
          name: 'expiry',
          title: 'columns.priceAlarmTable.expiry',
          originalName: 'expiry',
          rowVisible: true,
          searchable: true,
          formatters: ['expiry']
        },
        {
          name: 'price',
          title: 'columns.priceAlarmTable.price',
          originalName: 'priceLevel',
          rowVisible: true,
          formatters: ['price']
        },
        {
          name: 'priceAlarmType',
          title: 'columns.priceAlarmTable.priceAlarmType',
          originalName: 'priceAlarmType',
          rowVisible: true,
          formatters: ['priceAlarmType']
        },
        {
          name: 'expires',
          title: 'columns.priceAlarmTable.expires',
          originalName: 'expires',
          rowVisible: true,
          formatters: ['expires']
        },
        {
          name: 'customer',
          title: 'columns.priceAlarmTable.customer',
          originalName: 'customer',
          rowVisible: true
        },
        {
          name: 'description',
          title: 'columns.priceAlarmTable.description',
          originalName: 'description',
          rowVisible: true
        },
        {
          name: 'priceAlarmDelete',
          title: 'columns.priceAlarmTable.delete',
          originalName: 'delete',
          rowVisible: true,
          formatters: ['priceAlarmDelete'],
          hiddenInTooltip: true
        },
        {
          name: 'priceAlarmStatus',
          title: 'columns.priceAlarmTable.status',
          originalName: 'triggered',
          rowVisible: true,
          formatters: ['priceAlarmStatus']
        }
      ],
      tableColumns: ['$index', 'contract', 'expiry', 'price', 'triggeredBy', 'expires', 'priceAlarmDelete', 'priceAlarmStatus'],
      recentTableColumns: ['$index', 'contract', 'price', 'priceAlarmStatus'],
      defaultSorting: <ITableSort[]> [
        {
          columnName: 'created',
          direction: -1
        }
      ],
      cellActions: {
        '*': 'showPriceAlarmTablePopover',
        'priceAlarmDelete': 'deletePriceAlarm'
      },
      cellActionClick: 'both'
    },
    logTable: {
      columns: <ITableColumn[]> [
        {
          name: '$index',
          title: '',
          originalName: '$index',
          rowVisible: false
        },
        {
          name: 'id',
          title: 'columns.logTable.id',
          originalName: 'id',
          rowVisible: true
        },
        {
          name: 'level',
          title: 'columns.logTable.level',
          originalName: 'level',
          rowVisible: true
        },
        {
          name: 'message',
          title: 'columns.logTable.message',
          originalName: 'message',
          rowVisible: true,
          searchable: true
        },
        {
          name: 'time',
          title: 'columns.logTable.time',
          originalName: 'time',
          rowVisible: true
        }
      ],
      tableColumns: ['$index', 'level', 'message', 'time'],
      recentTableColumns: ['$index', 'level', 'message', 'time'],
      defaultSorting: <ITableSort[]> [
        {
          columnName: 'time',
          direction: -1
        }
      ]
    }
  }
};
